import React from 'react';
import Select, { components } from 'react-select';

// Custom component for each option in the dropdown list
const CustomOption = (props) => {
  const { innerProps, data, isFocused } = props;

  // You can also read `isSelected` if you need distinct styling for the selected item
  // e.g., const { isSelected } = props;

  return (
    <div
      {...innerProps}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px',
        backgroundColor: isFocused ? '#eee' : 'transparent',
        cursor: 'pointer'
      }}
    >
      <img
        src={data.image}
        alt=""
        style={{ width: 30, height: 30, marginRight: 8, borderRadius: '50%' }}
      />
      <span>{data.label}</span>
    </div>
  );
};

// Custom component for the displayed single value (when an item is selected)
const CustomSingleValue = (props) => {
  const { data } = props;
  return (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={data.image}
          alt=""
          style={{ width: 30, height: 30, marginRight: 8, borderRadius: '50%' }}
        />
        {data.label}
      </div>
    </components.SingleValue>
  );
};

// You can define your own custom styles here
// React Select documentation: https://react-select.com/styles
const getCustomStyles = (isDarkMode) => ({
  container: (provided) => ({
    ...provided,
    width: '250px',    // <--- Increase the width here
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: isDarkMode ? '#444' : '#fff',
    borderColor: isDarkMode ? '#555' : '#ccc',
    color: isDarkMode ? '#ddd' : '#333',
    boxShadow: state.isFocused ? (isDarkMode ? '0 0 0 2px #444' : '0 0 0 2px #ddd') : null,
    '&:hover': {
      borderColor: isDarkMode ? '#666' : '#aaa',
    },
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '16px',
    backgroundColor: isDarkMode ? '#444' : '#fff',
    zIndex: 9999, // If you need it above other elements
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '16px',
    backgroundColor: state.isFocused
      ? isDarkMode
        ? '#555'
        : '#eee'
      : isDarkMode
      ? '#444'
      : '#fff',
    color: isDarkMode ? '#ddd' : '#333',
    cursor: 'pointer',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '16px',
    color: isDarkMode ? '#ddd' : '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: isDarkMode ? '#aaa' : '#999',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: isDarkMode ? '#ddd' : '#666',
    '&:hover': {
      color: isDarkMode ? '#fff' : '#333',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: isDarkMode ? '#666' : '#ccc',
  }),
});

export default function PersonalityDropdown({
  isDarkMode,
  personalityOptions,
  selectedPersonality,
  onPersonalityChange,
  refreshKey
}) {
  // Convert the current selection into the right shape for react-select
  const currentValue = personalityOptions.find(
    (option) => option.value === selectedPersonality
  );

  const handleChange = (selectedOption) => {
    // Call the parent's function, passing the "value"
    onPersonalityChange(selectedOption.value);
  };

  return (
    <Select
      key={refreshKey}
      value={currentValue}
      onChange={handleChange}
      options={personalityOptions}
      components={{
        Option: CustomOption,
        SingleValue: CustomSingleValue,
      }}
      styles={getCustomStyles(isDarkMode)}
      // Feel free to set placeholder, menuPlacement, etc.
      placeholder="Select a Personality..."
      menuPlacement="auto"
    />
  );
}
