import React, { useState } from 'react';
import './ImageGenerator.css';
import {firestoreManager} from "../Firebase/FirestoreManager";
import { getStorage, ref, uploadBytes, getDownloadURL, uploadString } from "firebase/storage";
import { toast, ToastContainer, Bounce } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const ImageGenerator = ({ userId, onTriggerRefresh }) => { // Assuming userId is passed as a prop
    const [prompt, setPrompt] = useState('');
    const [personalityDescription, setPersonalityDescription] = useState('');
    const [name, setName] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const onSendToast = (message) => {
        toast.success(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
        });
    }

    const handleGenerate = async () => {
        if (!personalityDescription.trim()) {
            setError('Please enter a description for the personality.');
            return;
        }

        setIsLoading(true);
        setError('');

        try {
            const response = await fetch('https://aspireai-418902.ue.r.appspot.com/generate/image', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    image_request: prompt,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to generate image.');
            }

            const data = await response.json();
            setImageUrl(data.image_url);
            onSendToast("📷 Image Generated 📷");
        } catch (err) {
            setError('An error occurred while generating the image.');
            console.error(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSave = async () => {
        if (!personalityDescription.trim()) {
          setError('Please enter a personality description.');
          return;
        }

        setIsLoading(true);
        setError('');

        try {
          const response = await fetch('https://aspireai-418902.ue.r.appspot.com/save/personality', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name: name || null,
              personality_description: personalityDescription,
              image_url: imageUrl || null,
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to save personality.');
          }

          const data = await response.json();

          // Update state with name & image URL
          if (data.name) setName(data.name);
          if (data.image_url) setImageUrl(data.image_url);

          // Actually save to your Firestore
          await firestoreManager.createPersonality(userId, data.name, personalityDescription, data.image_url);

          // Let the user know success happened
          onSendToast("🪶 New Aspirant created and saved 🧠");

          // *** Here is where you trigger the parent's refresh ***
          if (onTriggerRefresh) {
            onTriggerRefresh();
          }

        } catch (err) {
          setError('An error occurred while saving the personality.');
          console.error(err);
        } finally {
          setIsLoading(false);
        }
      };

    const handleClear = () => {
        setPrompt('');
        setPersonalityDescription('');
        setImageUrl('');
        setError('');
    };

    async function uploadPersonalityImage(userId, fileOrBase64) {
        const storage = getStorage();
        // Example path: "users/{userId}/personalities/{timestamp}.png"
        const timestamp = Date.now();
        const storageRef = ref(storage, `users/${userId}/personalities/${timestamp}.png`);

        // If you have a base64 data URL string:
        await uploadString(storageRef, fileOrBase64, 'data_url');

        // Alternatively, if you have an actual File object (e.g., from an <input>):
        // await uploadBytes(storageRef, fileOrFileObject);

        // Once uploaded, generate a stable download link
        const downloadURL = await getDownloadURL(storageRef);

        return downloadURL;
      }

      async function fetchImageAsBlob(imageUrl) {
          const response = await fetch(imageUrl);
          // If the request fails (e.g., expired token), handle the error
          if (!response.ok) {
            throw new Error(`Failed to fetch image from ${imageUrl}: ${response.status}`);
          }
          return response.blob();
        }

        async function uploadBlobToFirebase(blob, userId) {
            const storage = getStorage();
            const timestamp = Date.now();

            // Customize the path however you like.
            const storageRef = ref(storage, `users/${userId}/personalities/${timestamp}.png`);

            // Upload the blob
            await uploadBytes(storageRef, blob);

            // Now retrieve the permanent download URL from Firebase
            return getDownloadURL(storageRef);
          }

          async function savePersonalityWithImage(userId, name, description, externalImageUrl) {
              try {
                // 1) Get the image as a Blob from external URL
                const blob = await fetchImageAsBlob(externalImageUrl);

                // 2) Upload the Blob to Firebase Storage
                const firebaseImageUrl = await uploadBlobToFirebase(blob, userId);

                // 3) Create the personality doc with a stable download URL
                const docRef = await firestoreManager.createPersonality(
                  userId,
                  name,
                  description,
                  firebaseImageUrl // pass the permanent Firebase URL
                );

                return docRef;
              } catch (error) {
                console.error("Error saving personality with image:", error);
                throw error;
              }
            }

  return (
    <div className="image-generator-container">
        <ToastContainer
            position="top-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
        />
        <h2>Personality Creator</h2>
        <div className="input-section">
            <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter a name for the personality..."
            />
            <textarea
                value={personalityDescription}
                onChange={(e) => setPersonalityDescription(e.target.value)}
                placeholder="Describe the personality..."
                rows={3}
            />
            <textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Describe what you want the avatar to look like..."
                rows={3}
            />
        </div>
        <div className="button-section">
            <button onClick={handleGenerate} disabled={isLoading}>
                {isLoading ? 'Generating...' : 'Generate Avatar'}
            </button>
            <button onClick={handleSave} disabled={!personalityDescription || isLoading}>
                Save Personality
            </button>
            <button onClick={handleClear} disabled={!prompt && !personalityDescription && !imageUrl}>
                Clear
            </button>
        </div>
        {error && <p className="error-message">{error}</p>}
        {name && <p className="personality-name">Personality Name: {name}</p>}
        {imageUrl && (
            <div className="image-preview">
                <img src={imageUrl} alt="Generated Avatar" />
            </div>
        )}
    </div>
);
};

export default ImageGenerator;